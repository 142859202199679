var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CRow',[_c('CCol',{attrs:{"lg":"12"}},[_c('CCard',[_c('ValidationObserver',{ref:"form"},[_c('CCardHeader',{attrs:{"tag":"div"}},[_c('CIcon',{attrs:{"name":"cil-grid"}}),_vm._v(" Chi tiết quốc gia ")],1),_c('CCardBody',[_c('CForm',[_c('CRow',[_c('CCol',{attrs:{"lg":"6"}},[_c('ValidationProvider',{attrs:{"name":"Tên quốc gia","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
var touched = ref.touched;
return [_c('BaseInput',{staticStyle:{"background-color":"white"},attrs:{"disabled":"","label":"Tên quốc gia","horizontal":"","value":_vm.detailItem.ten,"is-valid":valid,"touched":touched,"invalid-feedback":errors},on:{"update:value":function($event){return _vm.$set(_vm.detailItem, "ten", $event)}}})]}}])})],1),_c('CCol',{attrs:{"lg":"6"}},[_c('ValidationProvider',{attrs:{"name":"Mã quốc gia","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
var touched = ref.touched;
return [_c('BaseInput',{staticStyle:{"background-color":"white"},attrs:{"disabled":"","label":"Mã quốc gia","horizontal":"","value":_vm.detailItem.ma,"is-valid":valid,"touched":touched,"invalid-feedback":errors},on:{"update:value":function($event){return _vm.$set(_vm.detailItem, "ma", $event)}}})]}}])})],1)],1),_c('CRow',[_c('CCol',{attrs:{"lg":"6"}},[_c('CRow',[_c('CCol',{staticClass:"col-form-label",attrs:{"tag":"label","sm":"3"}},[_vm._v("Trạng thái")]),_c('CCol',{staticClass:"form-inline",attrs:{"sm":"9"}},[_c('CInputCheckbox',{staticStyle:{"background-color":"white"},attrs:{"disabled":"","label":"Hoạt động","checked":_vm.detailItem.trangThai},on:{"update:checked":function($event){return _vm.$set(_vm.detailItem, "trangThai", $event)}}})],1)],1)],1)],1)],1)],1),_c('CCardFooter',{staticClass:"text-right",attrs:{"tag":"div"}},[_c('CButton',{staticClass:"mr-2",attrs:{"color":"secondary"},on:{"click":_vm.back}},[_c('CIcon',{attrs:{"name":"cil-arrow-left"}}),_vm._v(" Quay lại ")],1),_c('CButton',{staticClass:"mr-2",attrs:{"color":"primary"},on:{"click":_vm.editItem}},[_c('CIcon',{attrs:{"name":"cil-pencil"}}),_vm._v(" Sửa ")],1),_c('CButton',{attrs:{"color":"danger"},on:{"click":function($event){_vm.isDeleteConfirmationModalOpen = true}}},[_c('CIcon',{attrs:{"name":"cil-x"}}),_vm._v(" Xóa ")],1)],1)],1)],1)],1),_c('DeleteConfirmationModal',{attrs:{"title":"Xóa quốc gia","show":_vm.isDeleteConfirmationModalOpen,"is-deleting":_vm.isDeleting,"item":_vm.detailItem},on:{"update:show":function($event){_vm.isDeleteConfirmationModalOpen=$event},"confirm-destroy":_vm.confirmDestroy}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }